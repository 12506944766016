import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createTheme,
  CssBaseline,
  Grid,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import DropDown from "../components/DropDown";
import Footer from "../components/Footer";
import SideDrawer from "../components/SideDrawer";
import NavBar from "../components/NavBar";
import Capability from "../data/Capability";
import axios from "axios";

const globalTheme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#0c0a0b",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Google Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function removeLoader() {
  setTimeout(function () {
    document.getElementById("loader")?.classList.add("fade-out");
  }, 2000);
  setTimeout(function () {
    document.getElementById("loader")?.remove();
  }, 2500);
}

export default function Capabilities() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [capabilities, setCapabilities] = React.useState<Capability[]>([]);

  const handleProductsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenProducts((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    axios
      .post("/unmanned-dynamics-data-ingest", {
        action: "GET_CAPABILITIES",
      })
      .then((response) => {
        if (response.status === 200) {
          var list = [];
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i];
            var entry: Capability = {
              name: data[0],
              image: data[1],
              description: data[2],
            };

            list.push(entry);
          }

          setCapabilities(list);
          removeLoader();
        }
      });
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <SideDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <NavBar
        handleProductsClick={handleProductsClick}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
      <Grid
        container
        spacing={2}
        sx={{ px: { xs: 4, md: 6, lg: 8 }, pt: 4, minHeight: "80vh" }}
      >
        <Grid item xs={12} sx={{ py: 3 }}>
          <Typography variant="h4" fontWeight="bold">
            Capabilities
          </Typography>
        </Grid>

        {capabilities.length === 0 ? (
          <Grid container spacing={3} sx={{ pt: 3 }}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={500} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={500} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={500} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={500} />
            </Grid>
          </Grid>
        ) : null}

        {capabilities.map((data) => {
          return (
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ minHeight: { xs: "100px", md: "400px" } }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="180"
                    image={data.image}
                    alt="Control System Design"
                  />
                  <CardContent sx={{ whiteSpace: "normal" }}>
                    <Typography gutterBottom variant="h5" component="div">
                      {data.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {data.description.split("|").map((points) => {
                        return <li>{points}</li>;
                      })}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Grid container>
        <Footer />
      </Grid>
      <DropDown
        open={openProducts}
        anchorEl={anchorEl}
        setOpenProducts={setOpenProducts}
        setOpenDrawer={setOpenDrawer}
      />
    </ThemeProvider>
  );
}
