import {
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import DropDown from "../components/DropDown";
import Footer from "../components/Footer";
import SideDrawer from "../components/SideDrawer";
import NavBar from "../components/NavBar";
import axios from "axios";
import Technology from "../data/Technology";

const globalTheme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#0c0a0b",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Google Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function removeLoader() {
  setTimeout(function () {
    document.getElementById("loader")?.classList.add("fade-out");
  }, 2000);
  setTimeout(function () {
    document.getElementById("loader")?.remove();
  }, 2500);
}

export default function Technologies() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [technologies, setTechnologies] = React.useState<Technology[]>([]);

  const handleProductsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenProducts((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    axios
      .post("/unmanned-dynamics-data-ingest", {
        action: "GET_TECHNOLOGIES",
      })
      .then((response) => {
        if (response.status === 200) {
          var list = [];
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i];
            var entry: Technology = {
              name: data[0],
              category: data[1],
              description: data[2],
              application: data[3],
              link: data[4],
            };

            list.push(entry);
          }

          setTechnologies(list);
          removeLoader();
        }
      });
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <SideDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <NavBar
        handleProductsClick={handleProductsClick}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
      <Grid container spacing={2} sx={{ px: { xs: 4, md: 6, lg: 8 }, pt: 4 }}>
        <Grid item xs={12} sx={{ py: 3 }}>
          <Typography variant="h4" fontWeight="bold">
            Technologies
          </Typography>
        </Grid>

        {technologies.length === 0 ? (
          <Grid container spacing={3} sx={{ p: 3 }}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
          </Grid>
        ) : null}

        {technologies.map((data) => {
          return (
            <Grid item xs={12} md={6} lg={3} xl={2}>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  minHeight: {
                    xs: "100px",
                    md: "300px",
                    lg: "300px",
                    xl: "320px",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    pt: 1,
                    minHeight: "4em",
                    textDecoration: data.link !== undefined ? "underline" : "",
                    cursor: data.link !== undefined ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (data.link !== undefined) {
                      window.open(data.link, "_blank");
                    }
                  }}
                  color={data.link !== undefined ? "darkblue" : ""}
                >
                  {data.name}
                </Typography>
                <Typography variant="body1" sx={{ pt: 1, minHeight: "7em" }}>
                  {data.description}
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                  {data.application}
                </Typography>
              </Paper>
            </Grid>
          );
        })}

        <Footer />
      </Grid>
      <DropDown
        open={openProducts}
        anchorEl={anchorEl}
        setOpenProducts={setOpenProducts}
        setOpenDrawer={setOpenDrawer}
      />
    </ThemeProvider>
  );
}
