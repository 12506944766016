import { Grid, Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

export default function NavBar(props: {
  handleProductsClick: MouseEventHandler<HTMLButtonElement> | undefined;
  setOpenDrawer: (arg0: boolean) => void;
  openDrawer: any;
}) {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <img
          style={{
            paddingTop: "0.5rem",
            width: "250px",
            height: "55px",
            cursor: "pointer",
          }}
          onClick={() => redirectToHome()}
          src={"logo.png"}
          alt="Unmanned Dynamics Logo"
        />
      </Grid>
      <Grid item xs={8} sx={{ alignSelf: "center", textAlign: "right", pr: 1 }}>
        <Button
          variant="text"
          endIcon={<KeyboardArrowDownIcon />}
          sx={{ px: 2, display: { xs: "none", md: "inline-flex" } }}
          onClick={props.handleProductsClick}
        >
          Products
        </Button>
        <Button
          href="/capabilities"
          variant="text"
          sx={{ px: 2, display: { xs: "none", md: "inline-flex" } }}
        >
          Capabilities
        </Button>
        <Button
          href="/technologies"
          variant="text"
          sx={{ px: 2, display: { xs: "none", md: "inline-flex" } }}
        >
          Technologies
        </Button>
        <Button
          href="/about"
          variant="text"
          sx={{ px: 2, display: { xs: "none", md: "inline-flex" } }}
        >
          About Us
        </Button>
        <Button
          href="/contact"
          variant="text"
          sx={{ px: 2, display: { xs: "none", md: "inline-flex" } }}
        >
          Contact
        </Button>
        <IconButton
          aria-label="menu"
          sx={{ float: "right", pt: 2, display: { xs: "block", md: "none" } }}
          onClick={() => props.setOpenDrawer(!props.openDrawer)}
        >
          <MenuIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
