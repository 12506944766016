import { TreeView, TreeItem } from "@mui/lab";
import { Fade, Paper, Popper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router";
import { VirtualElement } from "@popperjs/core";

export default function DropDown(props: {
  open: boolean;
  anchorEl: VirtualElement | (() => VirtualElement) | null | undefined;
  setOpenProducts: (arg0: boolean) => void;
  setOpenDrawer: (arg0: boolean) => void;
}) {
  const navigate = useNavigate();

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      transition
      sx={{ zIndex: 1 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{
              border: 1,
              p: 2,
              pr: 4,
              mt: 1,
              bgcolor: "background.paper",
            }}
          >
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem nodeId="1" label="Aerial Products" sx={{ py: 1 }}>
                <TreeItem
                  nodeId="2"
                  label="Hybrid Drones"
                  sx={{ py: 1, pt: 2 }}
                  onClick={() => {
                    props.setOpenProducts(false);
                    props.setOpenDrawer(false);
                    navigate("/products");
                  }}
                />
                <TreeItem
                  nodeId="4"
                  label="High Payload Drones"
                  sx={{ py: 1 }}
                  onClick={() => {
                    props.setOpenProducts(false);
                    props.setOpenDrawer(false);
                    navigate("/products");
                  }}
                />
                <TreeItem
                  nodeId="6"
                  label="Battery Powered Drones"
                  sx={{ py: 1 }}
                  onClick={() => {
                    props.setOpenProducts(false);
                    props.setOpenDrawer(false);
                    navigate("/products");
                  }}
                />
              </TreeItem>
            </TreeView>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}
