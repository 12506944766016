import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./index.css";
import AboutUs from "./pages/AboutUs";
import Capabilities from "./pages/Capabilities";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Technologies from "./pages/Technologies";

axios.defaults.baseURL = "https://us-central1-habiot.cloudfunctions.net/";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/capabilities" element={<Capabilities />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
