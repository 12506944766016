import {
  createTheme,
  CssBaseline,
  Grid,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import DropDown from "../components/DropDown";
import Footer from "../components/Footer";
import SideDrawer from "../components/SideDrawer";
import NavBar from "../components/NavBar";
import axios from "axios";
import Team from "../data/Team";

const globalTheme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#0c0a0b",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Google Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function removeLoader() {
  setTimeout(function () {
    document.getElementById("loader")?.classList.add("fade-out");
  }, 2000);
  setTimeout(function () {
    document.getElementById("loader")?.remove();
  }, 2500);
}

export default function AboutUs() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [team, setTeam] = React.useState<Team[]>([]);

  const handleProductsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenProducts((previousOpen) => !previousOpen);
  };

  useEffect(() => {
    axios
      .post("/unmanned-dynamics-data-ingest", {
        action: "GET_TEAM",
      })
      .then((response) => {
        if (response.status === 200) {
          var list = [];
          for (let i = 0; i < response.data.length; i++) {
            let data = response.data[i];
            var entry: Team = {
              name: data[0],
              designation: data[1],
              description: data[2],
              link: data[3],
            };

            list.push(entry);
          }

          setTeam(list);
          removeLoader();
        }
      });
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <SideDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <NavBar
        handleProductsClick={handleProductsClick}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
      <Grid container>
        {/* ABOUT US SECTION */}
        <Grid
          id="about-us"
          item
          md={12}
          lg={6}
          sx={{ alignSelf: "center", px: { xs: 4, md: 6, lg: 8 }, py: 4 }}
        >
          <Typography variant="h4" fontWeight="bold" sx={{ pb: 4 }}>
            About Us
          </Typography>
          <Typography fontSize="1.2rem">
            Unmanned Dynamics is a multi-disciplinary team of scientists and
            engineers who convert innovative ideas to world class products. A
            brief description of the heads of our R&D divisions is given below
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          lg={6}
          sx={{
            textAlign: "center",
            py: 4,
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            alt="about-us"
            style={{ width: "-webkit-fill-available" }}
            src="/images/about/about-us.jpeg"
          />
        </Grid>

        {team.length === 0 ? (
          <Grid container spacing={3} sx={{ p: 3 }}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Skeleton height={400} />
            </Grid>
          </Grid>
        ) : null}

        {team.map((data) => {
          return (
            <Grid
              item
              md={12}
              lg={6}
              sx={{
                px: { xs: 4, md: 6, lg: 8 },
                py: { xs: 1, md: 2, lg: 4 },
                wordBreak: "break-word",
                cursor: data.link !== undefined ? "pointer" : "",
              }}
              onClick={() => {
                if (data.link !== undefined) {
                  window.open(data.link, "_blank");
                }
              }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{
                  pt: { xs: 3, lg: 4 },
                  textDecoration: data.link !== undefined ? "underline" : "",
                }}
                color={data.link !== undefined ? "darkblue" : ""}
              >
                {data.name}
              </Typography>
              <Typography variant="h6" sx={{ pt: 1, pb: 2 }}>
                {data.designation}
              </Typography>
              <Typography>{data.description}</Typography>
            </Grid>
          );
        })}

        <Footer />
      </Grid>
      <DropDown
        open={openProducts}
        anchorEl={anchorEl}
        setOpenProducts={setOpenProducts}
        setOpenDrawer={setOpenDrawer}
      />
    </ThemeProvider>
  );
}
